@import "./_mixins";
@import "./_variables";

.#{$prefix} {
  &-container {
    max-width: 1245px;
    margin: auto;
  }
}

.#{$prefix} {
  &-w-fit {
    width: fit-content;
  }
  &-w-full {
    width: 100%;
  }
}

.#{$prefix} {
  &-h-fit {
    height: fit-content;
  }
  &-h-full {
    height: 100%;
  }

  &-h-100vh {
    height: 100vh;
  }

  &-w-100vw {
    width: 100vw;
  }
}

@for $i from 1 through 100 {
  .#{$prefix} {
    &-w-#{$i} {
      width: calc($i * 1%) !important;
    }
  }
  .#{$prefix} {
    &-h-#{$i} {
      height: calc($i * 1vh) !important;
    }
  }
}

.#{$prefix} {
  &-w-auto {
    width: auto !important;
  }

  &-wx-auto {
    width: 0 auto !important;
  }
}

@include breakpoint(mobile) {
  @for $i from 1 through 100 {
    .#{$prefix}-sm {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix}-sm {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }

  .#{$prefix}-sm {
    &-w-auto {
      width: auto !important;
    }
  }
}

@include breakpoint(tablet) {
  @for $i from 1 through 100 {
    .#{$prefix}-md {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix}-md {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }

  .#{$prefix}-md {
    &-w-auto {
      width: auto !important;
    }
  }
}

@include breakpoint(bigTablet) {
  @for $i from 1 through 100 {
    .#{$prefix}-lg {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix}-lg {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }

  .#{$prefix}-lg {
    &-w-auto {
      width: auto !important;
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 1 through 100 {
    .#{$prefix}-xl {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix}-xl {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }

  .#{$prefix}-xl {
    &-w-auto {
      width: auto !important;
    }
  }
}

@include breakpoint(bigscreen) {
  @for $i from 1 through 100 {
    .#{$prefix}-2xl {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix}-2xl {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }

  .#{$prefix}-2xl {
    &-w-auto {
      width: auto !important;
    }
  }
}
