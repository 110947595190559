@import "./mixins";
@import "./variables";

.#{$prefix} {
  &-border-circle {
    border-radius: 50% !important;
  }

  &-border-8 {
    border-radius: 8px !important;
  }
  &-border-6 {
    border-radius: 6px !important;
  }

  &-border-4 {
    border-radius: 4px !important;
  }
  &-border-0 {
    border-radius: 0 !important;
  }
}

.#{$prefix} {
  &-outline-none {
    outline: none !important;
  }
}

.#{$prefix} {
  &-border-2 {
    border-width: 2px !important;
  }
}

// Border color
@each $color, $value in $colors {
  .#{$prefix} {
    &-border-#{$color} {
      border: 1px solid $value !important;
    }

    &-border-top-#{$color} {
      border-top: 1px solid $value !important;
    }

    &-border-bottom-#{$color} {
      border-bottom: 1px solid $value !important;
    }

    &-border-left-#{$color} {
      border-left: 1px solid $value !important;
    }

    &-border-right-#{$color} {
      border-right: 1px solid $value !important;
    }
  }
}

@include breakpoint(mobile) {
  .#{$prefix}-sm {
    &-border-circle {
      border-radius: 50% !important;
    }

    &-border-8 {
      border-radius: 8px !important;
    }

    &-border-4 {
      border-radius: 4px !important;
    }
    &-border-0 {
      border-radius: 0 !important;
    }
  }

  @each $color, $value in $colors {
    .#{$prefix}-sm {
      &-border-#{$color} {
        border: 1px solid $value !important;
      }

      &-border-top-#{$color} {
        border-top: 1px solid $value !important;
      }

      &-border-bottom-#{$color} {
        border-bottom: 1px solid $value !important;
      }

      &-border-left-#{$color} {
        border-left: 1px solid $value !important;
      }

      &-border-right-#{$color} {
        border-right: 1px solid $value !important;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$prefix}-md {
    &-border-circle {
      border-radius: 50% !important;
    }

    &-border-8 {
      border-radius: 8px !important;
    }

    &-border-4 {
      border-radius: 4px !important;
    }
    &-border-0 {
      border-radius: 0 !important;
    }
  }

  @each $color, $value in $colors {
    .#{$prefix}-md {
      &-border-#{$color} {
        border: 1px solid $value !important;
      }

      &-border-top-#{$color} {
        border-top: 1px solid $value !important;
      }

      &-border-bottom-#{$color} {
        border-bottom: 1px solid $value !important;
      }

      &-border-left-#{$color} {
        border-left: 1px solid $value !important;
      }

      &-border-right-#{$color} {
        border-right: 1px solid $value !important;
      }
    }
  }
}

@include breakpoint(bigTablet) {
  .#{$prefix}-lg {
    &-border-circle {
      border-radius: 50% !important;
    }

    &-border-8 {
      border-radius: 8px !important;
    }

    &-border-4 {
      border-radius: 4px !important;
    }
    &-border-0 {
      border-radius: 0 !important;
    }
  }

  @each $color, $value in $colors {
    .#{$prefix}-lg {
      &-border-#{$color} {
        border: 1px solid $value !important;
      }

      &-border-top-#{$color} {
        border-top: 1px solid $value !important;
      }

      &-border-bottom-#{$color} {
        border-bottom: 1px solid $value !important;
      }

      &-border-left-#{$color} {
        border-left: 1px solid $value !important;
      }

      &-border-right-#{$color} {
        border-right: 1px solid $value !important;
      }
    }
  }
}

@include breakpoint(desktop) {
  .#{$prefix}-xl {
    &-border-circle {
      border-radius: 50% !important;
    }

    &-border-8 {
      border-radius: 8px !important;
    }

    &-border-4 {
      border-radius: 4px !important;
    }
    &-border-0 {
      border-radius: 0 !important;
    }
  }

  @each $color, $value in $colors {
    .#{$prefix}-xl {
      &-border-#{$color} {
        border: 1px solid $value !important;
      }

      &-border-top-#{$color} {
        border-top: 1px solid $value !important;
      }

      &-border-bottom-#{$color} {
        border-bottom: 1px solid $value !important;
      }

      &-border-left-#{$color} {
        border-left: 1px solid $value !important;
      }

      &-border-right-#{$color} {
        border-right: 1px solid $value !important;
      }
    }
  }
}

@include breakpoint(bigscreen) {
  .#{$prefix}-2xl {
    &-border-circle {
      border-radius: 50% !important;
    }

    &-border-8 {
      border-radius: 8px !important;
    }

    &-border-4 {
      border-radius: 4px !important;
    }
    &-border-0 {
      border-radius: 0 !important;
    }
  }

  @each $color, $value in $colors {
    .#{$prefix}-2xl {
      &-border-#{$color} {
        border: 1px solid $value !important;
      }

      &-border-top-#{$color} {
        border-top: 1px solid $value !important;
      }

      &-border-bottom-#{$color} {
        border-bottom: 1px solid $value !important;
      }

      &-border-left-#{$color} {
        border-left: 1px solid $value !important;
      }

      &-border-right-#{$color} {
        border-right: 1px solid $value !important;
      }
    }
  }
}
