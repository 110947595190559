@import "./_variables";
@import "./_mixins";

@for $i from 0 through $opacity-range {
  .hrv-gga {
    &-opacity-#{$i} {
      opacity: calc(#{$i} / 10) !important;
    }
  }
}

@include breakpoint(mobile) {
  @for $i from 0 through $opacity-range {
    .hrv-gga-sm {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through $opacity-range {
    .hrv-gga-md {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(bigTablet) {
  @for $i from 0 through $opacity-range {
    .hrv-gga-lg {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through $opacity-range {
    .hrv-gga-xl {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(bigscreen) {
  @for $i from 0 through $opacity-range {
    .hrv-gga-2xl {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}
