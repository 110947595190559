.hrv-pagination {
  align-items: inherit !important;
}

.hrv-table thead th {
  color: #252525 !important;
}

.has-error {
  border-color: #f5222d !important;
}

.hrv-fileupload--container {
  background: none !important;
  padding: 0 !important;
}

.hrv-fileupload--box {
  min-height: 96px;
  padding: 8px;
}

.hrv-input-radio-horizon .hrv-next-input-radio + .hrv-next-input-radio {
  margin-top: 0;
}

.hrv-switch-disabled,
.hrv-switch-disabled .hrv-switch:after {
  cursor: not-allowed !important;
}

.hrv-daterangepicker .hrv-daterangepicker--time {
  text-align: left;
}

.hrv-select-selection__rendered {
  padding: 9.2px 0 !important;
}

.hrv-table {
  white-space: normal !important;
}

@keyframes scrollUpModal {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }
}

@keyframes visibaleBgModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hrv-btn {
  min-width: 0px !important;
}

@media (max-width: 767px) {
  .hrv-gga-table-wrapper {
    background: none;

    .hrv-table-container {
      &::-webkit-scrollbar {
        height: 4px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
      }

      td {
        &:nth-child(1) {
          min-width: 90px;
        }

        &:nth-child(2) {
          min-width: 245px;
        }

        &:nth-child(3) {
          min-width: 320px;
        }

        &:nth-child(4) {
          min-width: 140px;
        }

        &:nth-child(5) {
          min-width: 125px;
        }

        &:nth-child(6) {
          min-width: 200px;
        }
      }

      tr:last-child td {
        box-shadow: none;
      }
    }

    .hrv-pagination-dropdown {
      height: 40px;

      .hrv-pagination-dropdown--text {
        display: none;
      }
    }
  }

  .rc-dialog-mask {
    animation: visibaleBgModal 0.3s ease-in-out forwards;
  }

  .rc-dialog {
    position: fixed !important;
    z-index: 1051;
    left: 0;
    right: 0;
    margin: 0px !important;
    animation: scrollUpModal 0.3s ease-in-out forwards;

    &.hrv-gga-mt-100 {
      margin-top: auto !important;
    }

    .rc-dialog-content {
      margin-top: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      overflow: hidden;
    }
  }

  .daterangepicker {
    width: calc(100% - 40px) !important;
  }

  .hrv-btn {
    width: 100%;
    margin-top: 8px;
  }

  .hrv-pagination--listpage {
    padding-left: 0px;
  }

  .daterangepicker .drp-buttons .drp-selected {
    padding-bottom: 8px;
  }
}

.hrv-btn.hrv-btn-default {
  box-shadow: none !important;
  color: #3d4f6e !important;

  &:hover {
    opacity: 0.7;
  }
}

.hrv-daterangepicker {
  background: none !important;
  z-index: 1 !important;
}

.hrv-daterangepicker--calendar {
  display: none !important;
}

.hrv-daterangepicker.hrv-gga-datepicker-custom .hrv-daterangepicker--icon {
  color: #212121;
  margin-right: 22px;
  margin-top: 2px;
}

.hrv-gga-table-th-checkbox {
  width: 3%;
}

.hrv-gga-small-loading {
  min-height: unset !important;
  padding: 0 10px;

  & > .hrv-loading-main {
    width: 13px;
    height: 13px;
    margin-top: -7px;
    margin-left: -9px;
    border-color: #186ade;
    border-right-color: transparent;
    border-width: 1.5px;
  }
}

.hrv-gga-custom-range-rail-style {
  .hrv-range-handle {
    width: 20px !important;
    height: 20px !important;
    margin-top: -8px !important;
    margin-left: -7px !important;
    background-color: #2979ff !important;
    border: 2px solid #fff !important;
  }

  .hrv-range-rail {
    // border-bottom: 4px dashed #9ca3b0 !important;
    background-image: url("../../images/smart/Indicator.svg") !important;
  }

  &:hover .hrv-range-track {
    background-color: #2979ff !important;
  }
}

// .hrv-gga-custom-range-rail-style ~ .hrv-tooltip {
//   .hrv-tooltip-inner {
//     font-weight: 500 !important;
//     font-size: 12px !important;
//     color: #111827 !important;
//     background: #ffffff !important;
//     border-radius: 4px !important;
//     filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.24))
//       drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));
//   }
//   .hrv-tooltip-arrow {
//     display: none !important;
//   }
// }
// .hrv-tooltip-inner {
//   font-weight: 500 !important;
//   font-size: 12px !important;
//   color: #111827 !important;
//   background: #ffffff !important;
//   border-radius: 4px !important;
//   filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.24))
//     drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));
// }
// .hrv-tooltip-arrow {
//   display: none !important;
// }

.hrv-gga-custom-dialog {
  & > .rc-dialog-content {
    .rc-dialog-close {
      top: 14px;
    }

    .rc-dialog-header {
      padding: 16px !important;
    }

    .rc-dialog-body {
      padding: 16px !important;
    }
  }
}

.hrv-gga-custom-info-alert {
  background: #f0f6ff;
  border: 1px solid #91c3fd;
  border-radius: 8px;
  margin-bottom: 0;
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;

  .hrv-alert-message {
    flex: 1;
  }
}

.hrv-gga-table-th-checkbox {
  width: 3%;
}

.hrv-gga-custom-product-status-table {
  tbody td {
    box-shadow: unset;
    border-bottom: 1px solid #e5e7eb !important;

    &.no-border-b {
      border-bottom: unset !important;
    }
  }

  thead tr th {
    padding: 0;
    border-bottom: 1px solid #e5e7eb !important;
  }

  tbody tr td {
    padding: 0;
  }

  thead th:first-child,
  tbody td:first-child,
  tfoot td:first-child {
    padding: 0;
  }

  thead th:last-child,
  tbody td:last-child,
  tfoot td:last-child {
    padding: 0;
  }

  tbody td > div {
    padding-right: 16px;
  }

  thead th > * {
    padding-right: 16px;
  }

  thead th:first-child {
    padding: 0 18px;
  }

  thead th:last-child {
    padding: 0 23px;
  }

  tbody td:first-child {
    padding: 0 18px;
  }

  tbody td:last-child {
    padding: 0 23px;
  }

  thead th > * {
    padding: 14px 0;
    padding-right: 16px;
  }

  tbody td > div {
    padding: 10px 0;
  }

  thead th {
    background-color: #ffffff !important;
  }

  thead th:first-child > * {
    padding: 0;
  }

  thead th:last-child > * {
    padding: 0;
  }

  tbody td:last-child > div {
    padding: 0;
  }

  tbody td:first-child > div {
    padding: 0;
  }

  .hrv-gga-table-selected-item {
    padding: 0 !important;
  }
}

.overflowX-table {
  overflow-x: auto;
}

.overflowX-table table {
  min-width: 1000px !important;
}

.hrv-gga-blocker-custom {
  .rc-dialog-body {
    padding: 16px;
    border-radius: 8px;
  }
}

.custom-datepicker {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 8px 12px;
}

.hrv-gga-custom-radio-group {
  margin-top: 16px;

  & > .hrv-next-input-radio {
    margin: 0;
    font-family: "Inter";
    font-size: 14px;
    line-height: 20px;
    color: #111827;
  }

  & > .hrv-next-input-radio:not(:first-child) {
    margin-top: 12px;
  }
}

.hrv-gga-custom-radio-group-vertical.hrv-gga-custom-radio-group {
  display: flex;

  & > .hrv-next-input-radio {
    width: 50%;
  }

  & > .hrv-next-input-radio:not(:first-child) {
    margin-top: 0px;
  }
}

.hrv-gga-singledatepicker-input {
  border: 1px solid #e5e7eb !important;
  border-radius: 4px !important;

  & > .hrv-daterangepicker--input {
    & > .hrv-daterangepicker--content {
      background: #ffffff !important;

      & > .hrv-daterangepicker--time {
        padding-left: 32px !important;
        color: #111827 !important;
        font-style: 14px !important;
        font-weight: 400 !important;
      }
    }
  }

  & > .hrv-daterangepicker--icon {
    background: #ffffff !important;
  }
}

.hrv-selected {
  transition: all 0.3s;
}

.hrv-selected:hover {
  background: #f5f5f5;
  border-radius: 4px;
}

.hrv-selected-active {
  background: #f0f6ff !important;
  border-radius: 4px;

  &:hover {
    background: #f0f6ff !important;
  }
}

.hrv-gga-text-input__readonly {
  background: #ffffff !important;
}

.hrv-gga-btn-disabled {
  color: #6d7175 !important;
}

.hrv-next-input[disabled] {
  color: #21212166 !important;
}

.hrv-gga-custom-tooltip-header {
  white-space: pre-line;
}
