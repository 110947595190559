.hrv-gga-button-support {
  &:hover {
    p {
      color: #0056b3 !important;
    }

    span {
      svg > path {
        fill: #0056b3 !important;
      }
    }
  }
  transition: all 0.3s;
}
