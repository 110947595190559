img {
  max-width: 100%;
  &.w-24 {
    width: 24px !important;
    height: 24px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &.w-20 {
    width: 20px !important;
    height: 20px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &.w-16 {
    width: 16px !important;
    height: 16px !important;
  }
  &.w-30 {
    width: 30px;
  }
}

.img {
  &-circle {
    border-radius: 50%;
  }
  &-100 {
    width: 100px;
  }
}
