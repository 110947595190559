@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

// PREFIX
$prefix: hrv-gga;

// FONT_FAMILY
$font-family-primary: "Inter", Arial !default;
$font-family-secondary: "Roboto", Arial !default;

// COLORS
$white: #fff;

$black-1: #202223;
$black-2: #202124;
$black-3: #0a1f44;
$black-4: #212121;
$black-5: #4b5563;
$black-6: #111827;
$black-7: #424242;

$gray-1: #808080;
$gray-2: #d9d9d9;
$gray-3: #595959;
$gray-4: #d9d9d9;
$gray-5: #616161;
$gray-6: #333333;
$gray-7: #6d7175;
$gray-8: #e0e0e0;
$gray-9: #d1d5db;
$gray-10: #e8e8e8;
$gray-11: #e1e1e1;
$gray-12: #e8eaed;
$gray-13: #9ca3b0;
$gray-14: #f0f0f0;
$gray-15: #e5e7eb;
$gray-16: #f0ecec;

$blue-1: #042659;
$blue-2: #2962ff;
$blue-3: #1f58eb;
$blue-4: #0b3d88;
$blue-5: #3383ff;
$blue-6: #2463eb;
$blue-7: #e3eeff;
$blue-8: #0279c7;
$blue-9: #2f80ed;
$blue-10: #91c3fd;
$blue-11: #2979ff;

$red-1: #ee3333;
$red-2: #d82c0d;
$red-3: #dc2828;
$red-4: #cc3300;
$red-5: #ba1c1c;
$red-6: #fca6a6;

$green-1: #22c993;

$yellow-1: #ffc453;
$yellow-2: #db7706;
$yellow-3: #f0e0c0;
$yellow-4: #9c6f19;
$yellow-5: #fdbd41;
$yellow-6: #fcd44f;

// BREAKPOINTS
$bigscreen: 1400px; //2xl
$desktop: 1200px; //xl
$bigTablet: 992px; //lg
$tablet: 769px; //md
$mobile: 438px; //sm

// GUTTER
$gutter: 0.75rem; //đổi đơn vị rem sang px ta được gutter-px
$gutter-px: 12px;
$gutter-row: 1.5rem;

// SPACINGS
$spacing: 1px;

// Effect
$opacity-range: 80;

// COLORFUL
$colors: (
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "gray-3": $gray-3,
  "gray-4": $gray-4,
  "gray-5": $gray-5,
  "gray-6": $gray-6,
  "gray-7": $gray-7,
  "gray-8": $gray-8,
  "gray-9": $gray-9,
  "gray-10": $gray-10,
  "gray-11": $gray-11,
  "gray-12": $gray-12,
  "gray-13": $gray-13,
  "gray-14": $gray-14,
  "gray-15": $gray-15,
  "gray-16": $gray-16,
  "black-1": $black-1,
  "black-2": $black-2,
  "black-3": $black-3,
  "black-4": $black-4,
  "black-5": $black-5,
  "black-6": $black-6,
  "black-7": $black-7,
  "blue-1": $blue-1,
  "blue-2": $blue-2,
  "blue-3": $blue-3,
  "blue-4": $blue-4,
  "blue-5": $blue-5,
  "blue-6": $blue-6,
  "blue-7": $blue-7,
  "blue-8": $blue-8,
  "blue-9": $blue-9,
  "blue-10": $blue-10,
  "blue-11": $blue-11,
  "red-1": $red-1,
  "red-2": $red-2,
  "red-3": $red-3,
  "red-4": $red-4,
  "red-5": $red-5,
  "red-6": $red-6,
  "green-1": $green-1,
  "yellow-1": $yellow-1,
  "yellow-2": $yellow-2,
  "yellow-3": $yellow-3,
  "yellow-4": $yellow-4,
  "yellow-5": $yellow-5,
  "yellow-6": $yellow-6,
  "white": $white,
);
