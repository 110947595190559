::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.25);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 0 4px 4px 0;
}
.hrv-gga-whitebox {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(10, 31, 68, 0.21);
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
}

.hrv-gga-whitebox-no-bs {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
}

.hrv-gga-table-wrapper {
  background-color: white;
}

.hrv-gga-width-100 {
  width: 100%;
}

.hrv-gga-cursor-pointer {
  cursor: pointer;
}

.hrv-gga-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0;
  transition: all 0.3s;
}

.hrv-gga-custom-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 224px;
}

.#{$prefix} {
  &-scrollbar {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(50, 50, 50, 0.08);
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(50, 50, 50, 0.04);
      width: 8px;
      height: 8px;
    }
  }
}

.hrv-gga-checkbox-area-list {
  height: 236px;
  overflow-y: auto;
  @media (max-width: 767px) {
    height: 220px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
}
.hrv-gga-custom-mw-800 {
  max-width: 800px;
}
.hrv-gga-custom-mw-600 {
  max-width: 600px;
}
.hrv-gga-custom-shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.hrv-gga-text-capitalize {
  text-transform: capitalize;
}
.hrv-gga-lst-none {
  list-style-type: none;
}
.hrv-gga-text-italic {
  font-style: italic;
}
.hrv-switch-disabled.hrv-switch:after {
  cursor: not-allowed !important;
}

.hrv-gga-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hrv-gga-bg-white {
  background-color: #fff !important;
}

.hrv-gga-variants .rc-dialog-body {
  padding: 0px !important;
}

.hrv-gga-warning-primary {
  border: 1px solid #91c3fd;
  border-radius: 8px;
}
.hrv-gga-border-status-warning {
  border: 1px solid #fcd44f;
  border-radius: 8px;
}
.hrv-gga-border {
  &-alert {
    &-primary {
      border: 1px solid #91c3fd;
      border-radius: 8px;
    }
    &-warning {
      border: 1px solid #fcd44f;
      border-radius: 8px;
    }
  }
}
.hrv-gga-none-hover {
  &:hover {
    color: #2463eb;
  }
}

.rotate-180 {
  transform: rotate(180deg);
  transition: all 0.3s ease-out;
}

.hrv-gga-overflow-y {
  overflow-y: auto;
}

.hrv-gga-disabled-bg {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  border-radius: 4px;
  z-index: 123;
}
